import isbot from "isbot";
import axios from "axios";
import axiosRetry from "axios-retry";

axiosRetry(axios, { retries: 3 });
axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

class CookieConsent {
    constructor() {
        if (isbot(navigator.userAgent)) {
            return false;
        }
        this.expiryInMilliseconds = 1000 * 60 * 60 * 24 * 14;// 14 days
        // this.expiryInMilliseconds = 1000 * 60 * 60 * 24;// 1 day
        // this.expiryInMilliseconds = 1000 * 60 * 60;// 1 hour
        this.url = window.location.href;
        this.domain = window.location.host.toLowerCase();
        this.live = !/\.test$/.test(this.domain);
        if (this.live) {
            this.endpoint = "https://cookies.digitonomy.co.uk/record";
        } else {
            this.endpoint = "http://cookie-consent.test/record";
        }
        this.element = null;
        this.cookieKey = "cookie_consent";
        this.cookieKeyConsentGiven = this.cookieKey + "_consent_given";
        this.cookieKeyConsentRef = this.cookieKey + "_ref";
        this.cookieConsentGivenExpiry = this.cookieKey + "_expiry";
        this.getToken();
        this.consentGiven = this.getItemFromLocalStorageWithExpiry(this.cookieKeyConsentGiven);

        // Handle the fact that local storage values are always strings
        if (this.consentGiven !== null) {
            this.consentGiven = (this.consentGiven === 'true');
        }

        this.initialize();
    }

    isSiteCashlady() {
        return /cashlady/.test(this.domain);
    }

    isSiteLittleLoans() {
        return /little\-loans/.test(this.domain);
    }

    isSiteThimbl() {
        return /thimbl/.test(this.domain);
    }

    isSiteCompareCreditCards() {
        return /compare-credit-cards/.test(this.domain);
    }

    isSiteCompareCreditCardsLocal() {
        return /ccc\-frontend/.test(this.domain);
    }

    isSiteCleverLoans() {
        return /clever\-loans/.test(this.domain);
    }

    isSiteLoanly() {
        return /loanly/.test(this.domain);
    }

    isSiteViva() {
        return /viva/.test(this.domain);
    }

    isSiteGrowingPower() {
        return /growing(-){0,1}power/.test(this.domain);
    }

    isSiteCashFlex() {
        return /cashflex/.test(this.domain);
    }

    isSiteCreditec() {
        return /creditec/.test(this.domain);
    }

    isSiteComparitec() {
        return /comparitec/.test(this.domain);
    }

    isSiteDigitonomy() {
        return /digitonomy/.test(this.domain);
    }

    isSiteRealRates() {
        return /realrates/.test(this.domain);
    }

    getItemFromLocalStorageWithExpiry(key) {
        const expiry = window.localStorage.getItem(this.cookieConsentGivenExpiry);
        const item = window.localStorage.getItem(key);
        let now = new Date();
        if (expiry == null || item == null || now.getTime() > expiry) {
            window.localStorage.removeItem(key);
            return null;
        } else {
            return item;
        }
    }

    updateVisit() {
        if (!this.getItemFromLocalStorageWithExpiry("cookie_consent_sent")) {
            if (this.ref && this.consentGiven != null) {
                try {
                    axios
                        .post(
                            this.endpoint + "/consent",
                            {
                                id: this.ref,
                                consent: this.consentGiven,
                            },
                            {
                                timeout: 5000,
                            }
                        )
                        .then((response) => {
                            if (response.status == 200) {
                                this.setItemInStorage(
                                    "cookie_consent_sent",
                                    true
                                );
                            }
                        }).catch((error) => {
                        });
                } catch (e) {}
            }
        }
    }

    getToken() {
        if (this.getItemFromLocalStorageWithExpiry(this.cookieKeyConsentRef)) {
            this.ref = this.getItemFromLocalStorageWithExpiry(this.cookieKeyConsentRef);
        } else {
            try {
                axios
                    .post(
                        this.endpoint + "/start",
                        {
                            url: this.url,
                        },
                        {
                            timeout: 5000,
                        }
                    )
                    .then((response) => {
                        this.ref = response.data.id;
                        this.setItemInStorage(
                            this.cookieKeyConsentRef,
                            this.ref
                        );
                        this.updateVisit();
                    });
            } catch (e) {}
        }
    }

    /**
     * Checks to see if the domain is one of the NPL sites
     * This list will need to be maintained to make sure the MAS nessage shows wgen ti should
     * @returns bool
     */
    isNpl() {
        return this.isSiteLittleLoans() ||
            this.isSiteCashlady() ||
            this.isSiteCleverLoans() ||
            this.isSiteLoanly() ||
            this.isSiteViva() ||
            this.isSiteGrowingPower() ||
            this.isSiteCashFlex();
    }

    getMasMessageTextColor() {
        if (this.isSiteCashlady()) {
            return "#FFFFFF";
        }
        return this.getButtonTextColor();
    }

    getPrimaryColor() {
        if (this.isSiteLittleLoans()) {
            return "#69B6C2";
        }
        if (this.isSiteCashlady()) {
            return "#712190";
        }
        if (this.isSiteThimbl()) {
            return "#333333";
        }
        if (this.isSiteCompareCreditCards()) {
            return "#4E1AB9";
        }
        if (this.isSiteCompareCreditCardsLocal()) {
            return "#2C0058";
        }
        if (this.isSiteCleverLoans()) {
            return "#868687";
        }
        if (this.isSiteLoanly()) {
            return "#414141";
        }
        if (this.isSiteViva()) {
            return "#777777";
        }
        if (this.isSiteGrowingPower()) {
            return "#010540";
        }
        if (this.isSiteCashFlex()) {
            return "#666666";
        }
        if (this.isSiteCreditec()) {
            return "#7c1ef7";
        }
        if (this.isSiteComparitec()) {
            return "#1A1919";
        }
        if (this.isSiteDigitonomy()) {
            return "#1A1919";
        }
        if (this.isSiteRealRates()) {
            return "#001766";
        }
        return "#712190";
    }
    
    getSecondaryColor() {
        if (this.isSiteLittleLoans()) {
            return "#EE7367";
        }
        if (this.isSiteCashlady()) {
            return "#EDB752";
        }
        if (this.isSiteThimbl()) {
            return "#804D8C";
        }
        if (this.isSiteCompareCreditCards()) {
            return "#FA00E1";
        }
        if (this.isSiteCompareCreditCardsLocal()) {
            return "#FA00E1";
        }
        if (this.isSiteCleverLoans()) {
            return "#56B79C";
        }
        if (this.isSiteLoanly()) {
            return "#4AA66B";
        }
        if (this.isSiteViva()) {
            return "#F0BA46";
        }
        if (this.isSiteGrowingPower()) {
            return "#596CEC";
        }
        if (this.isSiteCashFlex()) {
            return "#5DA38A";
        }
        if (this.isSiteCreditec()) {
            return "#7c1ef7";
        }
        if (this.isSiteComparitec()) {
            return "#EAD300";
        }
        if (this.isSiteDigitonomy()) {
            return "#F8DD42";
        }
        if (this.isSiteRealRates()) {
            return "#036EAF";
        }
        return "#EDB752";
    }
    
    getButtonTextColor() {
        if (this.isSiteLittleLoans()) {
            return "#FFFFFF";
        }
        if (this.isSiteCashlady()) {
            return "#181B2D";
        }
        if (this.isSiteThimbl()) {
            return "#FFFFFF";
        }
        if (this.isSiteCompareCreditCards()) {
            return "#FFFFFF";
        }
        if (this.isSiteCompareCreditCardsLocal()) {
            return "#FFFFFF";
        }
        if (this.isSiteCleverLoans()) {
            return "#FFFFFF";
        }
        if (this.isSiteLoanly()) {
            return "#FFFFFF";
        }
        if (this.isSiteViva()) {
            return "#1D1514";
        }
        if (this.isSiteGrowingPower()) {
            return "#FFFFFF";
        }
        if (this.isSiteCashFlex()) {
            return "#FFFFFF";
        }
        if (this.isSiteCreditec()) {
            return "#FFFFFF";
        }
        if (this.isSiteRealRates()) {
            return "#FFFFFF";
        }
        return "#181B2D";
    }

    getTextColor() {
        if (this.isSiteThimbl()) {
            return "#804D8C";
        }
        return "#898989";
    }

    getFontWeight() {
        if (this.isSiteThimbl()) {
            return "bold";
        }
        return "normal";
    }

    fireConsentDataLayer() {
        window.gtag = function () {
            dataLayer.push(arguments);
        }        
        if (this.consentGiven === true) {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: "nonEssentialCookiesOK",
                nonEssentialCookiesOK: this.consentGiven,
            });
            window.dataLayer.push('consent', 'update', {
                'ad_storage': 'granted',
                'ad_user_data': 'granted',
                'ad_personalization': 'granted',
                'analytics_storage': 'granted'
            });
            gtag('consent', 'update', {
                'ad_storage': 'granted',
                'ad_user_data': 'granted',
                'ad_personalization': 'granted',
                'analytics_storage': 'granted'
            });
        } else {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push('consent', 'default', {
                'ad_storage': 'denied',
                'ad_user_data': 'denied',
                'ad_personalization': 'denied',
                'analytics_storage': 'denied'
            });
            gtag('consent', 'default', {
                'ad_storage': 'denied',
                'ad_user_data': 'denied',
                'ad_personalization': 'denied',
                'analytics_storage': 'denied'
            });
        }
}

    setConsent(given) {
        this.consentGiven = (given === true);
        // Set the consent given
        this.setItemInStorage(
            this.cookieKeyConsentGiven,
            this.consentGiven
        );
        // Set the expiry
        this.setItemInStorage(
            this.cookieConsentGivenExpiry,
            (new Date()).getTime() + this.expiryInMilliseconds
        ); 
        this.updateVisit();
        this.fireConsentDataLayer();
        this.element.remove();
    }

    getCSS() {
        return `
            <style>
            #digitonomy_cookie_consent {
                z-index: 9999;
                position: fixed;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                display: flex;
                align-items: flex-end;
                color: #555555;
            }
            #digitonomy_cookie_consent > .modal-overlay {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                background-color: rgba(76, 76, 76, 0.8);
            }
            #digitonomy_cookie_consent > .modal {
                position: relative;
                display: flex;
                flex-direction: column;
                width: 100%;
                background-color: #FFF;
                height: auto;
                padding: 20px;
                overflow: hidden;
            }
            #digitonomy_cookie_consent .mas-message {
                padding: 12px 20px;
                font-size: 14px;
                background: ${this.getPrimaryColor()};
                color: ${this.getMasMessageTextColor()};
                min-height: 10px;
                margin-left: -20px;
                margin-right: -20px;
                margin-top: -20px;
                margin-bottom: 20px;
            }
            #digitonomy_cookie_consent .mas-message a {
                text-decoration: underline;
                color: ${this.getMasMessageTextColor()};
            }
            #digitonomy_cookie_consent h3 {
                font-weight: bold;
                font-size: 18px;
                color: ${this.getPrimaryColor()};
                margin-bottom: 8px;
            }
            #digitonomy_cookie_consent p {
                font-weight: normal;
                font-size: 14px;
                margin-bottom: 8px;
            }
            #digitonomy_cookie_consent p .cookie-policy-link {
                line-break: no-break;
                font-weight: bold;
                color: #555;
                text-decoration: underline;
                white-space: nowrap;
            }
            #digitonomy_cookie_consent button {
                position: relative;
                height: 60px;
                width: 100%;
                background-color: ${this.getSecondaryColor()};
                font-weight: bold;
                font-size: 14px;
                text-align: left;
                border-radius: 5px;
                padding: 0 61px 0 20px;
                color: ${this.getButtonTextColor()};
                margin-bottom: 8px;
            }
            #digitonomy_cookie_consent button::before {
                content: '';
                background-image: url("data:image/svg+xml,%3Csvg width='21' height='12' viewBox='0 0 21 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M20.5657 6.56569C20.8781 6.25327 20.8781 5.74673 20.5657 5.43431L15.4745 0.343146C15.1621 0.0307262 14.6556 0.0307262 14.3431 0.343146C14.0307 0.655565 14.0307 1.1621 14.3431 1.47452L18.8686 6L14.3431 10.5255C14.0307 10.8379 14.0307 11.3444 14.3431 11.6569C14.6556 11.9693 15.1621 11.9693 15.4745 11.6569L20.5657 6.56569ZM0 6.8H20V5.2H0V6.8Z' fill='%23181B2D'/%3E%3C/svg%3E%0A");
                display: block;
                height: 12px;
                width: 21px;
                position: absolute;
                top: 24px;
                right: 20px;
            }
            #digitonomy_cookie_consent .opt-out {
                color: ${this.getTextColor()};
                font-size: 14px;
                font-weight: ${this.getFontWeight()};
                text-decoration: underline;
            }
            @media (min-width: 1024px) {
                #digitonomy_cookie_consent {
                    align-items: center;
                    justify-content: center;
                }
                #digitonomy_cookie_consent > .modal {
                    max-width: 600px;
                    border-radius: 5px;
                }
            }
            </style>
        `;
    }

    getHTML() {
        return `
            <div id="digitonomy_cookie_consent">
                <div class="modal-overlay"></div>
                <div class="modal">` +
                    (this.isNpl() ? `<div class="mas-message"><b>Warning: Late repayment can cause you serious money problems. For help, go to <a href="https://moneyhelper.org.uk" target="_blank">moneyhelper.org.uk</a></b></div>` : ``) +
                    `<h3>How we use cookies</h3>
                    <p>
                        This website uses cookies to ensure you get the best experience and service from us, as well as for marketing, analytics, and personalised advertising. By clicking 'Accept all & continue' you’re agreeing to our use of cookies. If you want to know more, you can read our <a class="cookie-policy-link" href="/cookie-policy" target="LEGALS">cookie policy.</a>
                    </p>
                    <button type="button" class="accept-all">Accept all & continue</button>
                    <a href="javascript:void(0)" class="opt-out">Opt out of non-essential cookies</a>
                </div>
            </div>
        `;
    }

    attachToDom() {
        document.body.insertAdjacentHTML("beforeend", this.getCSS());
        document.body.insertAdjacentHTML("beforeend", this.getHTML());
        this.element = document.getElementById("digitonomy_cookie_consent");
    }

    setupEvents() {
        if (this.element) {
            this.element.addEventListener("click", (e) => {
                if (e.target.classList.contains("accept-all")) {
                    this.setConsent(true);
                }
                if (e.target.classList.contains("opt-out")) {
                    this.setConsent(false);
                }
            });
        }
    }

    initialize() {
        if (
            !this.open &&
            this.consentGiven === null &&
            !/cookie\-policy/.test(window.location.href)
        ) {
            // Get an id from the API
            this.attachToDom();
            this.setupEvents();
        }
        this.fireConsentDataLayer();
    }

    setItemInStorage(key, value) {
        try {
            window.localStorage.setItem(
                key,
                value
            );
        } catch (e) {

        }
    }
}

window.cookieConsent = new CookieConsent();
